export default {
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#999",
    modes: {
      text: "#fff",
      background: "#000",
      primary: "#0cf",
    },
  },
  fonts: {
    body: "myriad-pro-1, Arial",
  },
  space: [0, " 1rem", "2rem", "3rem", "4rem", 8, 16, 32, 64, 128, 256, 512],
  sizes: ["0px", "650px"],
  breakpoints: ['480px', '768px', '1024px', '1200px'],
  // Note: Styled componenets only work on select componenets visit: https://theme-ui.com/theme-spec/#styles for list
  styles: {
    div: {
      color: "#000000",
      fontFamily: "body",
      fontWeight: "100",
      marginX: ['0px','30px','60px', "150px"],
      paddingBottom: "100px",
    },
    h1: {
      fontSize: "720px",
      fontFamily: "Arial",
      fontWeight: "500",
      color: "primary",
      mt: 4,
      mb: 2,
    },
    header: {
      bg: "#f00",
      height: "200px",
      img: {
        height: "175px",
      },
    },
  },
};
